import React from "react"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Projects from "../../templates/project/related"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGripHorizontal,
  faLayerPlus,
  faLanguage,
  faWindow,
  faCloudUpload,
} from "@fortawesome/pro-duotone-svg-icons"

const SupportIcon = ({ icon }) => {
  switch (icon) {
    case "faGripHorizontal":
      return (
        <FontAwesomeIcon
          size="1x"
          className="text-purple-600"
          icon={faGripHorizontal}
        />
      )

    case "faLanguage":
      return (
        <FontAwesomeIcon
          size="1x"
          className="text-purple-600"
          icon={faLanguage}
        />
      )

    case "faLayerPlus":
      return (
        <FontAwesomeIcon
          size="1x"
          className="text-purple-600"
          icon={faLayerPlus}
        />
      )

    case "faWindow":
      return (
        <FontAwesomeIcon
          size="1x"
          className="text-purple-600"
          icon={faWindow}
        />
      )
    case "faCloudUpload":
      return (
        <FontAwesomeIcon
          size="1x"
          className="text-purple-600"
          icon={faCloudUpload}
        />
      )

    default:
      break
  }
}
export default function Project({ pageContext: solution }) {
  const title = solution.title.replace(/(<([^>]+)>)/gi, "")
  return (
    <Layout>
      <Seo title={title} />
      <section className="bg-blue-100">
        <div className="lg:relative py-8 sm:py-24 lg:py-24 container">
          <div className="max-w-md sm:max-w-3xl lg:max-w-6xl lg:grid lg:grid-cols-2">
            <div>
              <h1
                dangerouslySetInnerHTML={{ __html: solution.title }}
                className="mt-4 sm:mt-5 lg:mt-6"
              ></h1>
              <hr className="w-12 bg-transparent border-t-2 border-purple-600 mt-4 mb-6" />
              <h3 dangerouslySetInnerHTML={{ __html: solution.subtitle }}></h3>
              <p
                className="mt-5"
                dangerouslySetInnerHTML={{ __html: solution.description }}
              ></p>
            </div>
          </div>
          <div className="py-12 sm:relative sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-5/12 lg:max-w-2xl">
            <img
              alt={title}
              className="relative max-w-sm lg:max-w-none lg:h-full m-auto w-full object-contain"
              src={`https://storage.googleapis.com/keshif-website/Solutions/${solution.slug}/Main.png`}
            ></img>
          </div>
        </div>
      </section>
      <section className="container">
        <ul className="grid lg:gap-36 md:gap-24 gap-8 lg:mt-24 md:mt-16 mt-12">
          {solution.cards.map((i, k) => {
            return (
              <li
                key={k}
                className={`flex flex-wrap${
                  !k % 2 ? " lg:flex-row-reverse" : ""
                }`}
              >
                <img
                  alt={i.description}
                  className="lg:max-w-xl object-contain shadow-2xl"
                  src={`https://storage.googleapis.com/keshif-website/Solutions/${
                    solution.slug
                  }/Features-${k + 1}.png`}
                />
                <div
                  className={`pt-12 max-w-md ${
                    !k % 2 ? "md:mr-auto pr-6" : "md:ml-auto pl-6"
                  } flex-1 self-start`}
                >
                  <h2>{i.title}</h2>
                  <p className="mt-5">{i.description}</p>
                </div>
              </li>
            )
          })}
        </ul>
      </section>
      <section className="bg-gray-50 lg:mt-20 md:mt-15 mt-10">
        <div className="container lg:py-20 md:py-15 py-10">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-5">
            {solution.goals.map((i, k) => (
              <figure className="lg:block md:block flex" key={i.title}>
                <img
                  className="w-24 h-24 object-cover rounded-full border border-gray-200"
                  alt={i.title}
                  src={`https://storage.googleapis.com/keshif-website/Solutions/${
                    solution.slug
                  }/Textures-${k + 1}.png`}
                ></img>
                <figcaption className="text-sm leading-5 pr-4 lg:border-l md:border-l mt-5">
                  <p
                    dangerouslySetInnerHTML={{ __html: i.title }}
                    className="pl-5"
                  ></p>
                </figcaption>
              </figure>
            ))}
          </div>
        </div>
      </section>
      <section>
        <div className="container lg:py-20 md:py-15 py-10">
          <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-11">
            {solution.slug === "Elections" ? (
              <img
                alt={solution.slug}
                className="shadow-2xl"
                src={`https://storage.googleapis.com/keshif-website/Solutions/${solution.slug}/Services-1.png`}
              ></img>
            ) : (
              <div className="space-y-8">
                <img
                  alt={solution.slug}
                  className="shadow-2xl"
                  src={`https://storage.googleapis.com/keshif-website/Solutions/${solution.slug}/Services-1.png`}
                ></img>
                <img
                  alt={solution.slug}
                  className="shadow-2xl"
                  src={`https://storage.googleapis.com/keshif-website/Solutions/${solution.slug}/Services-2.png`}
                ></img>
              </div>
            )}
            <div className="grid gap-5">
              {solution.supports.items.map(i => (
                <div
                  key={i.icon}
                  className="bg-gray-50 rounded-sm p-7 flex hover:shadow-lg cursor-default transition-all"
                >
                  <div className="w-12 h-12 bg-purple-100 rounded-full grid place-items-center mr-5">
                    <SupportIcon icon={i.icon} />
                  </div>
                  <div className="flex-1">
                    <h5 className="font-medium mb-2">{i.title}</h5>
                    <p>{i.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <Projects
        title="Selected Projects & Partners"
        projects={solution.related_projects.slice(0, 3)}
      >
        <div className="grid lg:grid-cols-4 gap-4 grid-cols-2 items-center mt-12 mt:py-20 mt:py-28">
          {solution.partners.map(i => (
            <img
              key={i}
              className="object-contain h-11 lg:justify-self-center justify-self-start"
              alt={i}
              src={`https://storage.googleapis.com/keshif-website/Logos/${i}_BW.png`}
            ></img>
          ))}
        </div>
      </Projects>
    </Layout>
  )
}
